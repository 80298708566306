import "../styles/index.scss";

(function () {
   
   const menuToggle = document.getElementById('navbar-toggler');
   const nav = document.getElementById('navbar');
   const navClasses = nav.classList;
   const registrationForm = document.getElementById('registration_form');
   const contactForm = document.getElementById('contact_form');
   const previews = document.getElementsByClassName('preview');
   const url = '/api/contact';
   const inputs = {};
   const notify = document.getElementById('notify');
   const notifyTimeout = 4000;
   let isMenuOpen = false;

   const onClose = () => document.activeElement.blur();

   const onMenuToggle = () => {
      navClasses.toggle('show');
      if (isMenuOpen) navClasses.add('hide');
      if (navClasses.contains('show')) {
         navClasses.remove('hide');
      };
      isMenuOpen = !isMenuOpen;
   };

   const onSubmit = e => {
      e.preventDefault();
      e.target.forEach(input => {
         if (input.name === 'Organization_Name') inputs.orgName = input.value;
         if (input.name === 'First_Name') inputs.firstName = input.value;
         if (input.name === 'Last_Name') inputs.lastName = input.value;
         if (input.name === 'Country_of_Residence') inputs.country = input.value;
         if (input.name === 'Email_Address') inputs.email = input.value;
         if (input.name === 'User_Message') inputs.message = input.value;
         if (input.name === 'Customers' && input.checked === true) {
            if (input.id === 'Consumers_Customers') {
               inputs.customers = 'Consumers';
            } else if (input.id === 'Business_Customers') {
               inputs.customers = 'Business';
            }
         }
      });

      const onNotify = type => {
         notify.classList.add("active", type);
         const timeout = setTimeout(() => {
            notify.classList.remove("active", type);
            clearTimeout(timeout);
         }, notifyTimeout);
      };

      fetch(url, {
         headers: {
            "cache-control": "no-cache",
            "content-type": "application/json"
         },
         body: JSON.stringify(inputs),
         method: "POST"
      })
      .then(response => response.json())
      .then(result => {
         if (result.status === 'ok') onNotify('success');
      })
      .catch(() => onNotify('error'));
   };

   !!previews && previews.forEach(preview => preview.addEventListener('click', onClose));
   !!registrationForm && registrationForm.addEventListener('submit', onSubmit);
   !!contactForm && contactForm.addEventListener('submit', onSubmit);
   !!menuToggle && menuToggle.addEventListener('click', onMenuToggle);
   !!nav && nav.addEventListener('click', onMenuToggle);
})();
